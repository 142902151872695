import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';

import { combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { VbAuthBrowserFacadeService } from '@vb/auth/browser';

import { RootFacade } from '../../../state/root/root.facade';
import { SignupStatus } from '../../../state/root/root.reducer';

@Component({
    selector: 'app-newsletter-banner',
    styleUrls: ['./newsletter-banner.component.scss'],
    template: `
        <div class="newsletter-banner" *ngIf="!!(show$ | async)">
            <div class="newsletter-banner__col --small">
                <h2 *ngIf="title">{{ title }}</h2>
                <p *ngIf="description">
                    {{ description }}
                </p>
                <app-newsletter-form></app-newsletter-form>

                <div class="newsletter-loading" *ngIf="loading$ | async">
                    <mat-spinner diameter="40"></mat-spinner>
                </div>
            </div>
            <div class="newsletter-banner__col --big">
                <ui-image
                    static
                    class="newsletter-banner__img"
                    src="https://vinborsen-se.imgix.net/static/vinborsen-promo-1.png"
                    [lazyload]="true"
                    [sizes]="{
                        xs: [672, 404],
                        sm: [1032, 764],
                        md: [660, 392],
                        lg: [700, 432]
                    }"
                ></ui-image>
            </div>
        </div>
    `
})
export class AppSharedNewsletterBannerComponent implements OnDestroy {
    private _destroy$ = new Subject<void>();

    @Input() title: string | boolean = 'Få alla nya fynd direkt i mejlen.';
    @Input() description: string | boolean =
        'Bli medlem så får du alla tips om nya prissänkta och prisvärda produkter direkt till din mejl.';

    @Input() force = false;
    @Input() color: 'green' | undefined;

    @Output() readonly completed = new EventEmitter<void>();

    @HostBinding('attr.color') get attrBgColor() {
        return this.color || null;
    }

    loading$ = this._authFacade.loading$;

    show$ = combineLatest([this._authFacade.currentUser$, this._rootFacade.signupStatus$]).pipe(
        map(
            ([user, signupStatus]) =>
                !!this.force || !user || signupStatus !== SignupStatus.COMPLETED
        )
    );

    constructor(
        private _rootFacade: RootFacade,
        private _authFacade: VbAuthBrowserFacadeService
    ) {}

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
