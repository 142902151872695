import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

import { UiImageModule } from '@vb/ui/common/image';

import { AppSharedNewsletterBannerComponent } from './banner/newsletter-banner.component';
import { AppSharedNewsletterDialogComponent } from './dialog/signup-dialog.component';
import { AppSharedNewsletterTeaserDialogComponent } from './dialog/teaser-dialog.component';
import { AppSharedNewsletterWelcomeDialogComponent } from './dialog/welcome-dialog.component';
import { AppSharedNewsletterFormComponent } from './form/newsletter-form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    OverlayModule,
    PortalModule,

    UiImageModule,
    AppSharedNewsletterFormComponent,
  ],
  declarations: [
    AppSharedNewsletterBannerComponent,
    AppSharedNewsletterTeaserDialogComponent,
    AppSharedNewsletterWelcomeDialogComponent,
    AppSharedNewsletterDialogComponent,
  ],
  exports: [AppSharedNewsletterBannerComponent],
})
export class AppSharedNewsletterModule {}
